@import "./colors";

.white-tooltip{
  .ui-tooltip-text{
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    line-height: 20px;
    color: $darkGrey;
  }
}
