$white: #ffffff;
$black: #000000;

//Purple shades
$lightPurple: #f3f1ff;
$purple: #8270E7;

//Off-white shades
$offWhite: #F6F1E4;


//Grey shades
$whiteGrey: #f4f6f9;
$lightGrey: #eaeaea;
$lightGrey2: #E3E6ED;
$lightGrey3: #82899A;
$lightGrey4: #CBD3DF;
$lightGrey5: #e5e6e7;
$darkGrey: #676a6c;
$darkGrey2: #696F80;
$lightBlueGrey: #e2e4ec;
$lightBlueGrey1: #E7EDF7;
$midBlueGrey: #acb5d4;
$midGrey: #c4c4c4;
$midGray2: #4E515D;
$infoIconGrey: #9DAFDB;
$lightGrayBlue: #B4B8C7;
$gray30: #E4E7EE;


//Green shades
$lightGreen: #57c0a9;
$darkGreen: #4AB261;

//Blue shades
$mainBlue: #0042e4;
$darkBlue: #1b5e9c;
$darkBlue2: #1C1D21;
$hoverTextBlue: #0A2E87;
$lightBlue: #ebf7fe;
$lightBlue2: #1576ec;
$lightBlue3: #E6ECFC;
$lightBlue4: #618AEE;
$lightBlue5: #99B3F4;
$whiteBlue: #f4f8fd;
$midBlue: #5CA3E7;

//Navy shades
$darkNavy: #272850;
$darkTextNavy: #051146;
$midGrayBlue: #506AAA;
$lightGrayBlue: #b3c6f7;

$yellow: #fec600;
$midYellow: #fff4c9;
$darkYellow: #785e08;
$lightYellow: #fae499;
$alertYellow: #CF9F00;
$alertYellow2: #F3BB00;

//Red shades
$darkRed: #CB2222;
$darkRed2: #C02020;
$lightRed: #feedef;

//Orange shades
$orange: #FFB297;

//Pink shades
$light-pink: #ECE9FB;
$light-pink2: #6F5FC4;

//buttons
$product-buttons-actions-hover: #0032AC;
$product-buttons-actions: #0042E4;
$product-text:#676A6C;

