@import "apps/app/src/styles/styles";
@import '@shopify/polaris-tokens/dist/css/styles.css';
@import 'style.scss';

.Polaris-Box {
  --pc-box-padding-block-start-xs: var(--p-space-400);
  --pc-box-padding-block-start-md: var(--p-space-600);
  --pc-box-padding-block-end-xs: var(--p-space-400);
  --pc-box-padding-block-end-md: var(--p-space-600);
  --pc-box-padding-inline-start-xs: var(--p-space-400);
  --pc-box-padding-inline-start-sm: var(--p-space-0);
  --pc-box-padding-inline-end-xs: var(--p-space-400);
  --pc-box-padding-inline-end-sm: var(--p-space-0);
  position: relative;
}
