.flex-center {
    display: flex;
    align-items: center;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.pointer-events-none {
    pointer-events: none;
}

.cursor-move {
    cursor: move;
}

.heading-1 {
    font-family: 'Graphie', sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    color: var(--yotpo-color-general-100);
}

.heading-2 {
    font-family: 'Graphie', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--yotpo-color-general-100);
}

.subheading {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--yotpo-color-general-80);
}
