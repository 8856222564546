body .ui-button {
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}
body .ui-button.reviews-app-tertiary-button {
  background-color: transparent;
  border: 0px transparent;
  color: #676A6C;
}
body .ui-button.reviews-app-tertiary-button:hover {
  background-color: transparent;
  border: 0px transparent;
  color: #0032AC;
}
body .ui-button.reviews-app-blue-tertiary-button {
  background-color: transparent;
  border: 0px transparent;
  color: #0042e4;
  float: right;
}
body .ui-button.reviews-app-blue-tertiary-button:hover {
  background-color: transparent;
  border: 0px transparent;
  color: #0A2E87;
}
body .ui-button.reviews-app-secondary-button {
  background-color: transparent;
  color: #0042E4;
  border-color: #0042E4;
}
body .ui-button.reviews-app-secondary-button:hover {
  background-color: transparent;
  border: 1px solid #0032AC;
  color: #0032AC;
}
body .ui-button.reviews-app-command-bar {
  background-color: #ffffff;
  color: #0042e4;
  border-color: #0042e4;
  font-family: Inter;
  border-radius: 4px;
  font-weight: 400;
}
body .ui-button.reviews-app-command-bar .ui-button-text {
  margin-right: 130px;
}
body .ui-button.reviews-app-command-bar:hover {
  filter: drop-shadow(0px 0px 6px rgba(28, 29, 33, 0.12));
  background-color: #ffffff;
  color: #0042e4;
}
body .ui-button.ui-state-default:not(.reviews-app-secondary-button, .reviews-app-command-bar, .reviews-app-tertiary-button, .reviews-app-blue-tertiary-button, .ui-button-light, .ui-button-yellow) {
  background-color: #0042E4;
  border-color: #0042E4;
}
body .ui-button.ui-state-default:not(.reviews-app-secondary-button, .reviews-app-command-bar, .reviews-app-tertiary-button, .reviews-app-blue-tertiary-button, .ui-button-light, .ui-button-yellow):hover {
  background-color: #0032AC;
  border-color: #0032AC;
}

.white-tooltip .ui-tooltip-text {
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  line-height: 20px;
  color: #676a6c;
}