@import "apps/app/src/styles/styles";

body.email-templates-preview {
  .yo-email-editor-mask {
    background-color: white;
  }

  .email-templates-list {
    display: none;
  }

  .email-templates::part(overlay) {
    opacity: 0;
  }
}

body.email-editor-open, body.email-templates-preview {

  .side-bar, .sidebar-container {
    animation-duration: 250ms;
    animation-name: fadeOut;
  }

  .side-bar {
    transform: translateX(-60px);
  }

  .sidebar-container {
    transform: translateX(-240px);
  }

  .sidenav-second-level {
    transform: translateX(-300px);
    transition: transform 200ms;
  }

  .ui-dialog .ui-dialog-titlebar {
    border: 0;
  }

  .yo-email-editor-mask {
    top: 50px;
    background-color: $white;
    z-index: 1001 !important;
  }

  .yo-email-editor {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    box-shadow: none;

    .yo-email-editor-content {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: calc(100vh - 50px);
    }
  }
}

@keyframes fadeOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60px);
    display: none;
  }
}
