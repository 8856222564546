@import './colors';

body {
  .ui-button {
    font-family: Inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;

    &.reviews-app-tertiary-button {
      background-color: transparent;
      border: 0px transparent;
      color: $product-text;
      &:hover {
        background-color: transparent;
        border: 0px transparent;
        color: $product-buttons-actions-hover;
      }
    }
    &.reviews-app-blue-tertiary-button {
      background-color: transparent;
      border: 0px transparent;
      color: $mainBlue;
      float: right;

      &:hover {
        background-color: transparent;
        border: 0px transparent;
        color: $hoverTextBlue;
      }
    }

    &.reviews-app-secondary-button {
      background-color: transparent;
      color: $product-buttons-actions;
      border-color: $product-buttons-actions;
      &:hover {
        background-color: transparent;
        border: 1px solid $product-buttons-actions-hover;
        color: $product-buttons-actions-hover;
      }
    }

    &.reviews-app-command-bar {
      background-color: $white;
      color: $mainBlue;
      border-color: $mainBlue;
      font-family: Inter;
      border-radius: 4px;
      font-weight: 400;

      .ui-button-text{
        margin-right: 130px;
      }

      &:hover {
        filter: drop-shadow(0px 0px 6px rgba(28, 29, 33, 0.12));
        background-color: $white;
        color: $mainBlue;
      }
    }



    // override buttons
    &.ui-state-default:not(.reviews-app-secondary-button, .reviews-app-command-bar, .reviews-app-tertiary-button, .reviews-app-blue-tertiary-button, .ui-button-light, .ui-button-yellow) {
      background-color: $product-buttons-actions;
      border-color: $product-buttons-actions;

      // override :hover
      &:hover {
        background-color: $product-buttons-actions-hover;
        border-color: $product-buttons-actions-hover;
      }
    }
  }
}
