/* You can add global styles to this file, and also import other style files */
@import "./variables";
@import "./legacy-colors";
@import "./colors";
@import "./helper.scss";
@import './mixins.scss';

.content-container > div,
.yo-content > div {
  height: 100%;
}

.hbspt-form {
  display: none;
}

$success: #d6f1dc;
$successIconGreen: #137528;
$info: #EDF3FF;
$infoIconBlue: #0042E4;
$switchBlue: #1576ec;
$tableHeadersBlue: #8c9dc7;
$infoIconGrey: #9dafdb;
$lightMidGrey: #e7e7e7;
$spinnerGrey: #bcbcbc;
$warn: #FFF4C9;
$warnIconYellow: #98770B;
$error: #FFD8D9;
$errorIconRed: #CB2222;
$textColor: #272850;
$pageBackground: #f8fcff;

